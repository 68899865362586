import React, { useRef, useEffect, useCallback } from "react";
import { usePreferences, actions as accountActions } from "modules/account";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions as settingsActions } from "modules/settings";
import { strings, regionOptions } from "localization";
import { UnifiedSelectPanel } from "./UnifiedSelectPanel";

export const RegionPanel = () => {
  const dispatch = useDispatch();
  const userPreferences = usePreferences();

  const open = useSelector(selectors.getRegionPanelActive);

  const onClose = () => dispatch(settingsActions.toggleRegionPanel());

  const initialValue = useRef(userPreferences.region);
  useEffect(() => {
    if (initialValue.current !== userPreferences.region) {
      window.location.reload();
    }
  }, [initialValue, userPreferences]);

  const onSave = useCallback(
    (value: string) => {
      if (value === userPreferences.region) return;
      dispatch(
        accountActions.updatePreferences.request({
          ...userPreferences,
          region: value
        })
      );
    },
    [dispatch, userPreferences]
  );

  return (
    <UnifiedSelectPanel
      options={regionOptions}
      header={strings.layout.profile.dateTimeFormat}
      open={open}
      onClose={onClose}
      onSave={onSave}
    />
  );
};
