import { notify } from "hcss-components";
import React from "react";
import styled from "styled-components";
import { strings } from "localization";
import { isSandboxEnabled } from "core/util/sandbox";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ProjectUpdate } from "modules/projects/rtk";
import { ProjectValidationErrorDto, SchemaField } from "api";

export const save = (name: string) => {
  notify(
    "success",
    strings.formatString(strings.core.notifications.saved, name) as string
  );
};

export const success = (name: string) => {
  notify("success", name);
};

export const add = (name: string) => {
  notify(
    "success",
    strings.formatString(strings.core.notifications.added, name) as string
  );
};

export const unarchive = (name: string) => {
  notify(
    "success",
    strings.formatString(strings.core.notifications.unarchived, name) as string
  );
};

export const error = (headline: string, description?: React.ReactChild) => {
  const body = isSandboxEnabled() ? (
    <>
      <div>{description}</div>
      <span style={{ wordWrap: "break-word" }}>
        Some features are disabled in sandbox mode.
      </span>
    </>
  ) : (
    <div style={{ wordBreak: "break-word", wordWrap: "break-word" }}>
      {description}
    </div>
  );
  // @ts-ignore
  notify("danger", headline, body);
};

export const warning = (title: string, body: React.ReactChild) => {
  // @ts-ignore
  notify("warning", title, body);
};

export const remove = (name: string) => {
  notify(
    "warning",
    strings.formatString(strings.core.notifications.removed, name) as string
  );
};

const notifyDefault = (
  type: "success" | "info" | "warning" | "danger",
  title: string,
  body?: string | string[] | React.ReactChild
) => {
  if (Array.isArray(body)) {
    const Body = (
      <>
        {body.map((text: string, index) => (
          <p style={{ color: "#FFF" }} key={index}>
            {text}
          </p>
        ))}
      </>
    );
    // @ts-ignore
    notify(type, title, <Body />);
  }
  // @ts-ignore
  notify(type, title, body);
};

export const errorSavingProject = (
  originalArgs: ProjectUpdate,
  payload: FetchBaseQueryError,
  preConIdField?: SchemaField
) => {
  const data = payload.data as {
    errors: ProjectValidationErrorDto[];
    message: string;
  };
  try {
    if (preConIdField && payload.status === 409) {
      error(
        `${preConIdField["name"]}: ${originalArgs.fields?.["preconId"]?.["completedString"]} already exists.`
      );
    } else {
      const apiError = data.errors;
      const localizedErrorLookup = strings.import.errors
        .fieldsValidation as Record<string, string>;
      const errorMessages = apiError.map(e => {
        const { message, fieldName, invalidValue } = e;
        const errorMessage = localizedErrorLookup[message];
        return strings.formatString(
          errorMessage,
          JSON.stringify(invalidValue),
          fieldName
        ) as string;
      });

      if (errorMessages?.length && errorMessages.length > 0) {
        error(
          `Error Saving ${originalArgs.fields.name}`,
          errorMessages.join(" ")
        );
      } else {
        error(`Error Saving ${originalArgs.fields.name}`, data.message);
      }
    }
  } catch {
    error(`Error Saving ${originalArgs.fields.name}`);
  }
};

export const projectSaveWarning = (
  warnings: {
    message: string;
    fieldName: string;
    warningValue: string;
  }[]
) => {
  warnings.forEach(warn => {
    const key = warn.message as keyof typeof strings.projects.warnings.titles;
    const title =
      strings.projects.warnings.titles[key] ||
      strings.projects.warnings.titles.default;
    warning(
      title,
      strings.formatString(
        //@ts-ignore
        strings.projects.warnings.messages[warn.message],
        warn.fieldName,
        warn.warningValue
      ) as string
    );
  });
};

export default notifyDefault;

export const NotificationBody = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
`;

export const NotificationFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`;
