import { useSelector } from "react-redux";
import { useGetProjectsQuery, getLinkedEstimates } from "modules/projects/rtk";
import { selectors } from "modules/estimates";
import { WithId } from "core";
import { EstimateEntity } from "api";

export const useGetUnlinkedEstimates = () => {
  const { linkedEstimates } = useGetProjectsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      linkedEstimates: getLinkedEstimates(result)
    })
  });
  const estimates = useSelector(selectors.getAllEstimates);
  const unlinkedEstimates = estimates.filter(e => !linkedEstimates.has(e.id));
  const unlinkedEstimateCount = useGetUnlinkedAndUnhiddenEstimatesCount(
    unlinkedEstimates
  );

  return {
    estimates: unlinkedEstimates,
    unlinkedEstimateCount
  };
};

const useGetUnlinkedAndUnhiddenEstimatesCount = (
  estimates: WithId<EstimateEntity>[]
) => {
  const hiddenIds = useSelector(selectors.getHiddenUnlinkedEstimateIds);
  const hiddenIdsLoaded = useSelector(
    selectors.getHiddenUnlinkedEstimateIdsLoaded
  );
  if (!hiddenIdsLoaded) {
    return 0;
  }

  return estimates.filter(e => !hiddenIds.includes(e.id)).length;
};
