import { currentEnvironment, currentEnvironmentName, isLocal } from "config";
import { Middleware } from "redux";
import {
  AccountAuthorization,
  actions as accountActions,
  UserPayload
} from "modules/account";
import { UserType } from "core";
import { getType } from "typesafe-actions";
import { appInsights } from "logging/app-insights";
import { datadogRum } from "@datadog/browser-rum";

export const loggingMiddleware: Middleware = store => next => action => {
  try {
    if (!isLocal) {
      authIntercepter(action);
      gainsightAuthIntercepter(action);
      pendoAuthIntercepter(action);
      datadogAuthIntercepter(action);
    }
  } catch (error) {
    console.error(error);
  }
  return next(action);
};

interface UnknownAction {
  type?: string;

  payload?: unknown;
}

function authIntercepter(action: UnknownAction) {
  if (
    action.type === getType(accountActions.signInWithHcssCredentials.success)
  ) {
    const payload = action.payload as UserPayload;
    const { idsrvAccessToken, ...user } = payload.user;
    appInsights.setAuthenticatedUserContext(user.hcssUserId, user.companyId);
  }
}

function getProductTier(authorization: AccountAuthorization): string {
  if (authorization.canAccessFullPrecon) {
    return "FullPrecon";
  } else if (authorization.canAccessLimitedPrecon) {
    return "LimitedPrecon";
  } else if (authorization.canAccessFullReadonlyPrecon) {
    return "FullReadonlyPrecon";
  } else {
    return "";
  }
}

function gainsightAuthIntercepter(action: UnknownAction) {
  if (
    action.type === getType(accountActions.signInWithHcssCredentials.success)
  ) {
    const payload = action.payload as UserPayload;
    const { idsrvAccessToken, companyId, companyName, ...user } = payload.user;

    const productTier = getProductTier(payload.authorization);

    const aptrinsicUser = {
      ...user,
      id: user.hcssUserId,
      productTier: productTier
    };
    const aptrinsicCompany = {
      id: companyId,
      name: companyName
    };
    (window as any)?.aptrinsic("identify", aptrinsicUser, aptrinsicCompany);
  }
}

const pendoAuthIntercepter = (action: UnknownAction) => {
  if (
    action.type === getType(accountActions.signInWithHcssCredentials.success)
  ) {
    const payload = action.payload as UserPayload;
    const { companyId, companyName, ...user } = payload.user;

    const productTier = getProductTier(payload.authorization);
    const visitor = {
      id: user.hcssUserId,
      productTier: productTier,
      companyName: companyName,
      homeBusinessUnitId: user.homeBusinessUnitId,
      homeBusinessUnitName: user.homeBusinessUnitName,
      selectedBusinessUnitId: user.selectedBusinessUnitId,
      selectedBusinessUnitName: user.selectedBusinessUnitName,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      type: user.type,
      accessibleBusinessUnits: user.accessibleBusinessUnits.map(
        bu => bu.businessUnitId
      ),
      isCredentialAdmin: user.isCredentialAdmin,
      userName: user.userName,
      environment: currentEnvironment
    };

    const account = {
      id: companyId,
      companyName: companyName,
      planLevel: productTier,
      environment: currentEnvironment
    };

    (window as any)?.pendo.initialize({ visitor, account });
  }
};

const datadogAuthIntercepter = (action: UnknownAction) => {
  if (
    action.type === getType(accountActions.signInWithHcssCredentials.success)
  ) {
    const payload = action.payload as UserPayload;
    const { companyId, companyName, ...user } = payload.user;

    const productTier = getProductTier(payload.authorization);
    datadogRum.setUser({
      id: user.hcssUserId,
      companyId: companyId,
      homeBusinessUnitId: user.homeBusinessUnitId,
      selectedBusinessUnitId: user.selectedBusinessUnitId,
      environment: currentEnvironmentName,
      type: UserType[user.type],
      planLevel: productTier
    });
  }
};
