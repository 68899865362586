import { ProjectDetailsReportRequestDto } from "api/GeneratedClients/precon";
import HeavyJobApi from "api/HeavyJobApi";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { call, delay, put, takeEvery } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import {
  getSelectedBusinessUnitIdSaga,
  getTokenSaga
} from "../../api/api-saga-factory";
import { actions } from "./state";
import { PreConId } from "./common";

export class ProjectsApi {
  instance: AxiosInstance;
  businessUnitId: string;

  constructor(accessToken: string, businessUnitId: string) {
    this.instance = axios.create();
    this.instance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
    this.businessUnitId = businessUnitId;
  }
  getLastPreConIdAndReserveNext = () => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/projects/getLastPreConIdAndReserveNextId`;
    return this.instance.get<PreConId>(url);
  };

  discardReservedPreConId = (preConId: string) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/projects/discardReservedPreConId/${preConId}`;
    return this.instance.post(url);
  };

  getProjectDetailsReport = (
    projectId: string,
    body: ProjectDetailsReportRequestDto
  ) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/reports/projects/${projectId}/projectDetails`;
    const options = {
      responseType: "blob",
      method: "POST",
      url: url,
      headers: {
        Accept: "application/pdf"
      },
      data: body
    } as AxiosRequestConfig;
    return this.instance.request(options);
  };
}

function* loadHJJobs() {
  try {
    const token = yield call(getTokenSaga);
    const selectedBUId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new HeavyJobApi(token);
    const response = yield call(api.getJobs, selectedBUId);
    yield put(actions.loadHJJobs.success(response.data));
  } catch (error) {
    console.error(error);
    yield put(actions.loadHJJobs.failure(error));
  }
}

function* loadHJJobCostTypeCostsWithRetry(jobIds?: string[]) {
  const token = yield* getTokenSaga();
  const businessUnitId = yield* getSelectedBusinessUnitIdSaga();
  let error;
  const api = new HeavyJobApi(token);
  const maxRetryCount = 5;
  for (let retryCount = 0; retryCount < maxRetryCount; retryCount++) {
    try {
      const response = yield call(
        api.getJobCostTypeCosts,
        businessUnitId,
        jobIds
      );

      return response.data;
    } catch (err) {
      error = err;
      if (retryCount < maxRetryCount - 1) {
        yield delay(300 * (3 ^ retryCount));
      }
    }
  }

  throw error;
}

function* loadHJJobCostTypeCosts(
  action: ActionType<typeof actions.loadHJJobCostTypeCosts.request>
) {
  try {
    const data = yield* loadHJJobCostTypeCostsWithRetry(action.payload.jobIds);

    yield put(actions.loadHJJobCostTypeCosts.success(data));
  } catch (error) {
    console.error(error);
    yield put(actions.loadHJJobCostTypeCosts.failure(error));
  }
}

export const sagas = [
  takeEvery(getType(actions.loadHJJobs.request), loadHJJobs),
  takeEvery(
    getType(actions.loadHJJobCostTypeCosts.request),
    loadHJJobCostTypeCosts
  )
];
