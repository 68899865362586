import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import * as estimates from "./estimates";
import * as projects from "./projects";
import * as schemas from "./schemas";
import * as views from "./views";
import * as calendars from "./calendars";
import * as signalR from "./signalr";
import * as configurationSettings from "./configurationSettings";
import * as account from "./account";
import * as settings from "./settings";
import * as profile from "./profile";
import * as contacts from "./contacts";
import * as emailSubscriptions from "./email-subscriptions";
import * as quickPrice from "./quick-pricing";

import { loggingMiddleware } from "logging";
import { projectsApi } from "./projects/rtk";
import projects2Reducer from "./projects/projectsSlice";

// export type StoreState = account.StateSlice &
//   estimates.StateSlice &
//   calendars.StateSlice &
//   configurationSettings.StateSlice &
//   signalR.StateSlice &
//   schemas.StateSlice &
//   views.StateSlice &
//   projects.StateSlice &
//   settings.StateSlice &
//   profile.StateSlice &
//   contacts.StateSlice &
//   emailSubscriptions.StateSlice &
//   quickPrice.StateSlice;

export const rootReducer = combineReducers({
  account: account.reducer,
  schemas: schemas.reducer,
  projects: projects.reducer,
  estimates: estimates.reducer,
  views: views.reducer,
  signalR: signalR.reducer,
  configurationSettings: configurationSettings.reducer,
  calendars: calendars.reducer,
  settings: settings.reducer,
  profile: profile.reducer,
  contacts: contacts.reducer,
  emailSubscriptions: emailSubscriptions.reducer,
  quickPrice: quickPrice.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  projects2: projects2Reducer
});

const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

// let store: Store<StoreState>;

// if (process.env.NODE_ENV === `development`) {
//   const composeEnhancers =
//     typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//       ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//           trace: true,
//           traceLimit: 25
//         })
//       : compose;
//   const enhancer = composeEnhancers(
//     applyMiddleware(sagaMiddleware, loggingMiddleware)
//   );
//   store = createStore(rootReducer, enhancer);
// } else {
//   store = createStore(
//     rootReducer,
//     applyMiddleware(sagaMiddleware, loggingMiddleware)
//   );
// }

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      sagaMiddleware,
      projectsApi.middleware,
      loggingMiddleware
    )
});

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

const defaultExport = { store };
export default defaultExport;
