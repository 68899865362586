import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FieldMetadata, projectsApi } from "./rtk";
import { UndoEstimateLinkingState, UndoEstimateLinkingStatus } from "./state";
import { RootState } from "modules/store";
import { ProjectEstimate } from "api/GeneratedClients/precon";
import { strings } from "localization";
import * as notify from "../../core/components/notify";

type ProjectsState = {
  undoEstimateLinkingState: UndoEstimateLinkingState;
  fieldsMetadata: { [projectId: string]: Record<string, FieldMetadata> };
  errors: string[];
};

const slice = createSlice({
  name: "projects2",
  initialState: {
    undoEstimateLinkingState: {},
    fieldsMetadata: {},
    errors: []
  } as ProjectsState,
  reducers: {
    resetUndoEstimatesLinking: state => {
      state.undoEstimateLinkingState = {};
    },
    setFieldMetadata: (
      state,
      action: PayloadAction<{
        projectId: string;
        schemaFieldId: string;
        metadata: FieldMetadata;
      }>
    ) => {
      const { projectId, schemaFieldId, metadata } = action.payload;
      if (!state.fieldsMetadata[projectId]) {
        state.fieldsMetadata[projectId] = {};
      }
      state.fieldsMetadata[projectId][schemaFieldId] = metadata;
    },
    setFieldsMetadata: (
      state,
      action: PayloadAction<{
        projectId: string;
        metadata: Record<string, FieldMetadata>;
      }>
    ) => {
      const { projectId, metadata } = action.payload;
      state.fieldsMetadata[projectId] = metadata;
    },
    loadPreConIdFailure: state => {
      state.errors.push("Error fetching PreCon Id");
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(projectsApi.endpoints.archiveProject.matchFulfilled, () => {
        notify.warning(strings.projects.warnings.titles.projectArchived, "");
      })
      .addMatcher(projectsApi.endpoints.archiveProject.matchRejected, () => {
        notify.error(strings.projects.errors.archiveProject);
      })
      .addMatcher(
        projectsApi.endpoints.archiveMultipleProjects.matchFulfilled,
        (state, { payload }) => {
          if (payload.success.length) {
            notify.warning(
              strings.formatString(
                strings.core.notifications.archived,
                payload.success.length
              ) as string,
              ""
            );
          }
          if (payload.failed.length) {
            notify.error(
              strings
                .formatString(
                  strings.projects.errors.bulkArchive,
                  payload.failed.length
                )
                .toString()
            );
          }
        }
      )
      .addMatcher(
        projectsApi.endpoints.archiveMultipleProjects.matchRejected,
        (
          state,
          {
            meta: {
              arg: { originalArgs }
            }
          }
        ) => {
          notify.error(
            strings
              .formatString(
                strings.projects.errors.bulkArchive,
                originalArgs.length
              )
              .toString()
          );
        }
      )
      .addMatcher(
        projectsApi.endpoints.unarchiveProject.matchFulfilled,
        (state, { payload }) => {
          notify.unarchive(payload.fields.name);
        }
      )
      .addMatcher(projectsApi.endpoints.unarchiveProject.matchRejected, () => {
        notify.error(strings.projects.errors.unarchiveProject);
      })
      .addMatcher(projectsApi.endpoints.deleteProject.matchFulfilled, () => {
        notify.warning(strings.projects.warnings.titles.projectDeleted, "");
      })
      .addMatcher(projectsApi.endpoints.deleteProject.matchRejected, () => {
        notify.error(strings.projects.errors.deleteProject);
      })
      .addMatcher(
        projectsApi.endpoints.deleteMultipleProjects.matchFulfilled,
        (state, { meta }) => {
          notify.warning(
            strings.formatString(
              strings.projects.warnings.titles.bulkDelete,
              meta.arg.originalArgs.length
            ) as string,
            ""
          );
        }
      )
      .addMatcher(
        projectsApi.endpoints.deleteMultipleProjects.matchRejected,
        (state, { meta }) => {
          notify.error(
            strings.formatString(
              strings.projects.errors.bulkDelete,
              meta.arg.originalArgs.length
            ) as string
          );
        }
      )
      .addMatcher(
        projectsApi.endpoints.linkEstimatesToProject.matchPending,
        (
          state,
          {
            meta: {
              arg: { originalArgs }
            }
          }
        ) => {
          const originalProject = originalArgs.originalProject;
          const originalEstimatesIds = (
            (originalProject &&
              (originalProject.fields["estimates"] as ProjectEstimate[])) ||
            []
          ).map(e => e.id);
          const estimateIds = originalArgs.linkedEstimates.map(e => e.id);

          state.undoEstimateLinkingState = {
            projectId: originalArgs.id,
            linkedEstimatesIds: estimateIds.filter(
              id => !originalEstimatesIds.includes(id)
            ),
            error: undefined,
            status: UndoEstimateLinkingStatus.OptimisticallyReady
          };
        }
      )
      .addMatcher(
        projectsApi.endpoints.linkEstimatesToProject.matchFulfilled,
        (state, { payload }) => {
          notify.save(payload.fields.name);
          if (state.undoEstimateLinkingState.projectId === payload.id) {
            state.undoEstimateLinkingState = {
              ...state.undoEstimateLinkingState,
              error: undefined,
              status: UndoEstimateLinkingStatus.Ready
            };
          }
        }
      )
      .addMatcher(
        projectsApi.endpoints.linkEstimatesToProject.matchRejected,
        (state, action) => {
          state.undoEstimateLinkingState = {};
          notify.error(
            `Error linking estimates to project ${action.meta.arg.originalArgs.originalProject?.fields.name}`
          );
        }
      )
      .addMatcher(
        projectsApi.endpoints.unlinkEstimateFromProject.matchFulfilled,
        (
          state,
          {
            payload,
            meta: {
              arg: { originalArgs }
            }
          }
        ) =>
          notify.success(
            `${payload.fields.name} Saved! - ${originalArgs.estimateCode} estimate unlinked successfully`
          )
      )
      .addMatcher(
        projectsApi.endpoints.unlinkEstimateFromProject.matchRejected,
        (
          state,
          {
            meta: {
              arg: { originalArgs }
            }
          }
        ) =>
          notify.error(`Failed to unlink ${originalArgs.estimateCode} estimate`)
      )
      .addMatcher(
        projectsApi.endpoints.unlinkEstimatesFromProject.matchPending,
        (
          state,
          {
            meta: {
              arg: { originalArgs }
            }
          }
        ) => {
          const projectId = originalArgs.projectId;
          if (state.undoEstimateLinkingState === projectId) {
            state.undoEstimateLinkingState.status =
              UndoEstimateLinkingStatus.InProgress;
          }
        }
      )
      .addMatcher(
        projectsApi.endpoints.unlinkEstimatesFromProject.matchFulfilled,
        (
          state,
          {
            meta: {
              arg: { originalArgs }
            },
            payload
          }
        ) => {
          notify.success(
            `${payload.fields.name} Saved! - Estimate(s) unlinked successfully`
          );
          if (state.undoEstimateLinkingState === originalArgs.projectId) {
            state.undoEstimateLinkingState.status =
              UndoEstimateLinkingStatus.Ready;
          }
        }
      )
      .addMatcher(
        projectsApi.endpoints.unlinkEstimatesFromProject.matchRejected,
        (
          state,
          {
            error,
            meta: {
              arg: { originalArgs }
            }
          }
        ) => {
          if (
            state.undoEstimateLinkingState.projectId === originalArgs.projectId
          ) {
            state.undoEstimateLinkingState.status =
              UndoEstimateLinkingStatus.Fails;
            state.undoEstimateLinkingState.error = (error.message as unknown) as Error;
            if (originalArgs.meta?.errorNotification) {
              notify.error(originalArgs.meta.errorNotification);
            }
          }
        }
      )
      .addMatcher(
        projectsApi.endpoints.archiveMultipleProjects.matchFulfilled,
        (state, { payload }) => {
          if (payload.success.length) {
            notify.warning(
              strings.formatString(
                strings.core.notifications.archived,
                payload.success.length
              ) as string,
              ""
            );
          }
          if (payload.failed.length) {
            notify.error(
              strings
                .formatString(
                  strings.projects.errors.bulkArchive,
                  payload.failed.length
                )
                .toString()
            );
          }
        }
      )
      .addMatcher(
        projectsApi.endpoints.archiveMultipleProjects.matchRejected,
        (
          state,
          {
            meta: {
              arg: { originalArgs }
            }
          }
        ) => {
          notify.error(
            strings
              .formatString(
                strings.projects.errors.bulkArchive,
                originalArgs.length
              )
              .toString()
          );
        }
      )
      .addMatcher(
        projectsApi.endpoints.getProjects.matchFulfilled,
        (state, { payload }) => {
          Object.values(payload.entities).forEach(project => {
            const projectId = project.id;
            const metadata = project.fieldsMetadata;
            if (metadata) {
              state.fieldsMetadata[projectId] = metadata;
            }
          });
        }
      )
      .addMatcher(
        projectsApi.endpoints.getProjects.matchRejected,
        (state, { payload }) => {
          state.errors.push(
            `Error loading projects: ${
              payload?.originalStatus ?? payload?.status
            }`
          );
        }
      );
  }
});

export default slice.reducer;
export const {
  resetUndoEstimatesLinking,
  setFieldMetadata,
  setFieldsMetadata,
  loadPreConIdFailure
} = slice.actions;
export const selectUndoEstimateLinkingState = (state: RootState) =>
  state.projects2.undoEstimateLinkingState;
export const selectFieldsMetadata = (state: RootState) =>
  state.projects2.fieldsMetadata;
export const selectProjectErrors = (state: RootState) => {
  try {
    return state.projects2.errors;
  } catch {
    // Workaround for frontend tests. Remove once App.main.test.tsx is either modified or deleted
    return [];
  }
};
export const selectors = slice.selectors;
