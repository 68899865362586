import { Project, SchemaField, SchemaFieldType } from "api";
import { strings } from "localization";
import * as math from "mathjs";
import {
  CalculatedFieldConfig,
  CalculatedFieldVariable,
  CalculatedFieldVariableOption
} from "../models/calculated-field-config.model";
import { selectEstimatesFields } from "modules/projects/common/helpers";

const supportedOperators = ["+", "-", "*", "/", "^"];

const isOperatorNode = (node: math.MathNode): node is math.OperatorNode =>
  node.type === "OperatorNode";
const isSymbolNode = (node: math.MathNode): node is math.SymbolNode =>
  node.type === "SymbolNode";
const isConstantNode = (node: math.MathNode): node is math.ConstantNode =>
  node.type === "ConstantNode";

/**
 * Found all invalid operators in formula
 * @param nodes parsed formula root
 * @returns invalid operators
 */
const checkOperatorNodes = (nodes: math.MathNode) => {
  const operatorNodes = nodes.filter(isOperatorNode) as math.OperatorNode[];
  const invalidOperators: string[] = [];

  for (const operatorNode of operatorNodes) {
    if (!supportedOperators.includes(operatorNode.op)) {
      invalidOperators.push(operatorNode.op);
    }
  }

  // Distinct
  return invalidOperators.filter((v, i) => invalidOperators.indexOf(v) === i);
};

/**
 * Found all invalid numbers in formula
 * @param nodes parsed formula root
 * @returns invalid numbers
 */
const checkConstantNodes = (nodes: math.MathNode) => {
  const constantNodes = nodes.filter(isConstantNode) as math.ConstantNode[];
  const invalidNumbers: number[] = [];

  for (const constantNode of constantNodes) {
    const value = constantNode.value;

    // To big numbers
    if (value >= Number.MAX_SAFE_INTEGER) {
      invalidNumbers.push(value);
    }
  }

  // Distinct
  return invalidNumbers.filter((v, i) => invalidNumbers.indexOf(v) === i);
};
/**
 * validates variables in formula
 * @param nodes
 * @param allowedNames
 * @returns invalid variables
 */
const checkSymbolNodes = (nodes: math.MathNode, allowedNames: string[]) => {
  const symbolNodes = nodes.filter(n => isSymbolNode(n)) as math.SymbolNode[];
  const invalidVariables: string[] = [];

  for (const symbolNode of symbolNodes) {
    if (!allowedNames.includes(symbolNode.name)) {
      invalidVariables.push(symbolNode.name);
    }
  }

  // Distinct
  return invalidVariables.filter((v, i) => invalidVariables.indexOf(v) === i);
};

/**
 * Method to validate formula with defined variables
 * @param formula
 * @param variables
 * @param schema
 * @returns string errors
 */
const validateFormula = (
  formula: string,
  variables: CalculatedFieldVariable[]
): string[] => {
  try {
    const node = math.parse(formula);
    node.compile();

    const invalidVariables = checkSymbolNodes(
      node,
      variables.map(x => x.name)
    );

    const invalidOperators = checkOperatorNodes(node);

    const invalidNumbers = checkConstantNodes(node);

    const variablesErrors = invalidVariables.map(
      name =>
        strings.formatString(
          strings.schemas.setup.calculated.errors.invalidFormulaVariable,
          name
        ) as string
    );

    const operatorsErrors = invalidOperators.map(
      name =>
        strings.formatString(
          strings.schemas.setup.calculated.errors.invalidFormulaOperator,
          name
        ) as string
    );

    const numbersErrors = invalidNumbers.map(
      name =>
        strings.formatString(
          strings.schemas.setup.calculated.errors.invalidFormulaConstant,
          name
        ) as string
    );

    const errors = [...operatorsErrors, ...variablesErrors, ...numbersErrors];

    return errors;
  } catch (ex) {
    return [strings.schemas.setup.calculated.errors.invalidFormula];
  }
};

const validateVariable = (
  variable: CalculatedFieldVariable,
  allVariables: CalculatedFieldVariable[],
  options: CalculatedFieldVariableOption[]
) => {
  const nameErrors: string[] = [];
  const fieldIdErrors: string[] = [];

  const field = options.find(
    x =>
      x.fieldId === variable.fieldId &&
      (!x.isTableField || x.columnId === variable.columnId)
  );

  if (!field) {
    fieldIdErrors.push(
      strings.schemas.setup.calculated.errors.invalidVariableField
    );
  }

  if (!variable.name) {
    nameErrors.push(
      strings.schemas.setup.calculated.errors.requiredVariableName
    );
  }

  if (allVariables.filter(x => x.name === variable.name).length > 1) {
    nameErrors.push(
      strings.schemas.setup.calculated.errors.duplicateVariableName
    );
  }

  if (
    variable.name.match(/[^A-Za-z0-9]/g) ||
    !isNaN(+variable.name) ||
    !isNaN(parseFloat(variable.name))
  ) {
    nameErrors.push(
      strings.schemas.setup.calculated.errors.invalidVariableName
    );
  }

  if (variable.name.length > 255) {
    nameErrors.push(strings.schemas.setup.calculated.errors.maxLength);
  }

  if (nameErrors.length > 0 || fieldIdErrors.length > 0) {
    return {
      fieldIdErrors,
      nameErrors
    };
  }
};

export interface VariableErrors {
  [index: number]: {
    fieldIdErrors: string[];
    nameErrors: string[];
  };
}
/**
 * Method to validate variables
 * @param variables
 * @param schema
 * @returns maped variable errors {[variableName]: errors[]}
 */
const validateVariables = (
  variables: CalculatedFieldVariable[],
  fields: SchemaField[]
): VariableErrors => {
  const errors: VariableErrors = {};
  const variablesWithIndex = variables.map((x, i): [
    CalculatedFieldVariable,
    number
  ] => [x, i]);
  const options = getVariableOptions(fields);

  for (const [variable, index] of variablesWithIndex) {
    const validationErrors = validateVariable(variable, variables, options);

    if (validationErrors) {
      errors[index] = validationErrors;
    }
  }

  return errors;
};

const hasEstimatesVariables = ({
  variables,
  formula
}: CalculatedFieldConfig) => {
  try {
    const estimateVariables = variables.filter(v => v.isEstimateField === true);

    const nodes = math.parse(formula);
    const originFormula = nodes.toString();

    for (const variable of estimateVariables) {
      renameSymbolNodes(nodes, variable.name, "-");
    }

    const formulaWithoutEstimates = nodes.toString();

    return originFormula !== formulaWithoutEstimates;
  } catch {
    return false;
  }
};

const calculateAndFormat = (
  config: CalculatedFieldConfig,
  project: Pick<Project, "fields">,
  hasEstimatesAccess: boolean,
  round = true
): string => {
  if (!hasEstimatesAccess && hasEstimatesVariables(config)) {
    return "----";
  }

  const value = calculate(config, project, hasEstimatesAccess, round);

  return formatNumberConstant(value);
};

const calculate = (
  config: CalculatedFieldConfig,
  project: Pick<Project, "fields">,
  hasEstimatesAccess: boolean,
  round = true
): number => {
  try {
    if (!hasEstimatesAccess && hasEstimatesVariables(config)) {
      return NaN;
    }

    const nodes = math.parse(config.formula);
    const fn = nodes.compile();

    const scope: Record<string, number> = {};

    for (const variable of config.variables) {
      scope[variable.name] = getValueForField(project.fields, variable);
    }
    const result = fn.evaluate(scope);

    if (round) {
      return math.round(result, 2);
    }

    return result;
  } catch {
    return NaN;
  }
};

const getValueForField = (
  projectFields: Record<string, any>,
  variable: CalculatedFieldVariable
) => {
  let variableField = variable.fieldId;

  if (variable.isTableField && variable.columnId) {
    variableField = `table-total-${variable.fieldId}-${variable.columnId}`;
  }

  return projectFields[variableField] || 0;
};

const getConfig = (field: Pick<SchemaField, "config">) => {
  const config: CalculatedFieldConfig = field.config[
    "calculatedFieldConfig"
  ] || {
    variables: [],
    formula: ""
  };

  return config;
};

const setCalculateFields = (
  fields: SchemaField[],
  project: Pick<Project, "fields">,
  hasEstimatesAccess: boolean
) => {
  if (!project || !project.fields) {
    return;
  }

  for (const field of fields) {
    if (field.type !== SchemaFieldType.Calculated) {
      continue;
    }

    const config = getConfig(field);

    const calculatedValue = calculate(config, project, hasEstimatesAccess);

    project.fields[field.id] = calculatedValue;
  }
};

const renameSymbolNodes = (
  nodes: math.MathNode,
  oldName: string,
  newName: string
) => {
  const symbolNodes = nodes.filter(n => isSymbolNode(n)) as math.SymbolNode[];

  for (const node of symbolNodes) {
    if (node.name === oldName) {
      node.name = newName;
    }
  }
};

const changeVariableName = (
  formula: string,
  oldName: string,
  newName: string
): string => {
  try {
    const nodes = math.parse(formula);

    renameSymbolNodes(nodes, oldName, newName);

    return nodes.toString({
      handler: formatHandler
    });
  } catch (er) {
    return formula;
  }
};

const putFieldValuesInFormula = (
  formula: string,
  variables: CalculatedFieldVariable[],
  project: Pick<Project, "fields">,
  hasEstimatesAccess: boolean,
  format: "latex" | "text" = "latex"
): string => {
  try {
    const nodes = math.parse(formula);

    for (const variable of variables) {
      const dash = !hasEstimatesAccess && variable.isEstimateField;

      const variableValue = dash
        ? "----"
        : getValueForField(project.fields, variable);
      renameSymbolNodes(nodes, variable.name, (variableValue || 0).toString());
    }

    return format === "latex"
      ? nodes.toTex({
          handler: formatHandler
        })
      : nodes.toString({
          handler: formatHandler
        });
  } catch (er) {
    return formula;
  }
};

const getVariableOptions = (
  fields: SchemaField[]
): CalculatedFieldVariableOption[] => {
  const allowedFieldTypes = [
    SchemaFieldType.Number,
    SchemaFieldType.Currency,
    SchemaFieldType.Table
  ];

  const estimateFields = selectEstimatesFields(fields);
  const supportedProjectFields = fields
    .filter(f => allowedFieldTypes.includes(f.type))
    .filter(f => !f.lookup || estimateFields.indexOf(f) >= 0);

  const result: CalculatedFieldVariableOption[] = [];

  // All field types in same loop to keep origin order
  for (const field of supportedProjectFields) {
    if (field.type === SchemaFieldType.Table) {
      const tableColumnFields = getColumnFieldForTableFieldType(field);
      const tableVariableOptions: CalculatedFieldVariableOption[] = tableColumnFields.map(
        c => ({
          fieldId: field.id,
          isTableField: true,
          columnId: c.id,
          name: `[${field.name}] - '${c.name}' column`
        })
      );
      result.push(...tableVariableOptions);
    } else {
      result.push({
        fieldId: field.id,
        name: field.name,
        isEstimateField: estimateFields.indexOf(field) >= 0
      });
    }
  }

  return result;
};

const getColumnFieldForTableFieldType = (tableField: SchemaField) => {
  if (
    !tableField.config ||
    !tableField.config["columns"] ||
    tableField.config["columns"].length === 0
  )
    return [];
  const columns = tableField.config.columns as SchemaField[];
  const columnsWithSumTurnedOn = columns.filter(
    col =>
      col.config && col.config["sumColumn"] && col.config["sumColumn"] === true
  );

  return columnsWithSumTurnedOn;
};

const formatNumberConstant = (num: number): string => {
  if (isNaN(num)) {
    return "";
  }

  return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
};
// eslint-disable-next-line @typescript-eslint/ban-types
const formatHandler = (node: math.MathNode, options: object) => {
  if (isConstantNode(node)) {
    return formatNumberConstant(node.value);
  }

  if (isOperatorNode(node)) {
    node.implicit = false;
  }
};

const convertFormulaToLatexFormat = (formula: string) => {
  try {
    const nodes = math.parse(formula);

    return nodes.toTex();
  } catch (er) {
    return "";
  }
};

const tryFormatFormula = (formula: string) => {
  try {
    const nodes = math.parse(formula);

    return nodes.toString({
      handler: formatHandler
    });
  } catch (er) {
    return formula;
  }
};

export const calculatedFieldService = {
  convertFormulaToLatexFormat,
  changeVariableName,
  validateFormula,
  validateVariables,
  putFieldValuesInFormula,
  calculate,
  setCalculateFields,
  getConfig,
  getVariableOptions,
  supportedOperators,
  tryFormatFormula,
  formatNumber: formatNumberConstant,
  hasEstimatesVariables,
  calculateAndFormat
};
