import React, { MouseEventHandler, ReactNode } from "react";
import { error } from "core";
import { Button, Icon } from "hcss-components";
import { strings } from "localization";

export function enableSandboxMode() {
  window.localStorage.setItem("sandbox", "true");
  window.location.reload();
}

export function disableSandbox() {
  window.localStorage.removeItem("sandbox");
  window.location.reload();
}

export function resetSandbox() {
  window.localStorage.removeItem("sandbox-data");
  window.localStorage.removeItem("sandbox-config");
  window.localStorage.removeItem(
    "37682b0b-f27f-42b9-83e4-1b086a72716f-projectdetails-sections-collapsed"
  );
  window.location.reload();
}

export function disableCallbackInSandbox(cb: () => void) {
  const sandboxEnabled = isSandboxEnabled();
  if (sandboxEnabled) {
    return () => {
      error("Feature disabled");
    };
  } else return cb;
}

export const isSandboxEnabled = () => {
  const isSandboxEnabled = localStorage.getItem("sandbox");
  return isSandboxEnabled === "true";
};

export interface SandboxGuardProps {
  children: ReactNode;
}

export const DisableInSandbox = ({ children }: SandboxGuardProps) => {
  const sandboxEnabled = isSandboxEnabled();
  return sandboxEnabled ? (
    <div style={{ display: "contents" }} onClickCapture={onClickCapture}>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export const EnableInSandbox = ({ children }: SandboxGuardProps) => {
  const sandboxEnabled = isSandboxEnabled();
  return sandboxEnabled ? <>{children}</> : null;
};

export const HideInSandbox = ({ children }: SandboxGuardProps) => {
  const sandboxEnabled = isSandboxEnabled();
  return sandboxEnabled ? null : <>{children}</>;
};

export const onClickCapture: MouseEventHandler<HTMLElement> = e => {
  e.preventDefault();
  e.stopPropagation();
  error("Feature disabled");
};

export const DisableSandboxButton = () => {
  return (
    <EnableInSandbox>
      <Button hcssStyle="Delete" onClick={disableSandbox}>
        <Icon name="exclamation-octagon" margin="right" />{" "}
        {strings.errorBoundary.sandbox.disableButton}
      </Button>
    </EnableInSandbox>
  );
};
export const EnableSandboxButton = () => {
  return (
    <HideInSandbox>
      <Button onClick={enableSandboxMode}>
        <Icon name="exclamation-octagon" margin="right" />{" "}
        {strings.sandbox.enableSandboxButton}
      </Button>
    </HideInSandbox>
  );
};
export const ResetSandboxButton = () => {
  return (
    <EnableInSandbox>
      <Button
        style={{ marginLeft: "5px" }}
        onClick={resetSandbox}
        hcssStyle="ThemeInverted"
      >
        <Icon name="refresh" margin="right" />{" "}
        {strings.errorBoundary.sandbox.resetButton}
      </Button>
    </EnableInSandbox>
  );
};

export const SandboxToggleButton = () => {
  return (
    <>
      <DisableSandboxButton />
      <EnableSandboxButton />
    </>
  );
};
