import { isEqual } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  PreConId,
  ProjectsApi,
  enrichProjectWithEstimates,
  getEstimatesLookup,
  selectors
} from "modules/projects";
import { selectors as schemaSelectors } from "modules/schemas";
import {
  selectors as accountSelectors,
  useSelectedBusinessUnitId
} from "modules/account";
import { FieldMetadata, SchemaFieldType } from "api";
import { useCallback, useMemo, useState } from "react";
import rfdc from "rfdc";
import { calculatedFieldService } from "modules/schemas/services/calculated-field.service";
import { filterField } from "modules/schemas/filters";
import {
  useGetProjectsQuery,
  useGetArchivedProjectByIdQuery,
  selectProjectById
} from "./rtk";
import {
  selectFieldsMetadata,
  setFieldMetadata,
  setFieldsMetadata,
  loadPreConIdFailure
} from "./projectsSlice";

const fastDeepClone = rfdc();

export function useGetCurrentProject() {
  const { id: projectId } = useParams<{ id: string }>();
  const { data: currentProjectArchived } = useGetArchivedProjectByIdQuery(
    projectId
  );
  const { currentProjectActive } = useGetProjectsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      currentProjectActive: selectProjectById(result, projectId)
    })
  });

  return currentProjectActive ?? currentProjectArchived;
}

export const useProjectFieldsMetadata = (projectId: string) => {
  const dispatch = useDispatch();
  const projectsQuery = useGetProjectsQuery().data?.entities;
  const projectsLookupPristine = useMemo(() => projectsQuery ?? {}, [
    projectsQuery
  ]);

  const projectSchemaLookup = useSelector(schemaSelectors.getProjectSchema)
    ?.fields;

  const fieldsMetadata = useSelector(selectFieldsMetadata) || {};
  const projectFieldsMetadata = useMemo(() => fieldsMetadata[projectId] ?? {}, [
    projectId,
    fieldsMetadata
  ]);
  const projectFieldsMetadataPristine = useMemo(
    () => projectsLookupPristine[projectId]?.fieldsMetadata ?? {},
    [projectId, projectsLookupPristine]
  );

  const hasChanged = (() => {
    if (!projectId || !projectFieldsMetadata) return false;
    const oldFieldMetadata = Object.keys(projectFieldsMetadata)
      .filter(key => Object.keys(projectFieldsMetadataPristine).includes(key))
      .reduce((filteredObj, key) => {
        filteredObj[key] = projectFieldsMetadata[key];
        return filteredObj;
      }, {} as { [key: string]: any });
    const hasChangedInOldMetadata = !isEqual(
      oldFieldMetadata,
      projectFieldsMetadataPristine
    );

    const newFieldMetadataKeys = Object.keys(projectFieldsMetadata).filter(
      key => !Object.keys(projectFieldsMetadataPristine).includes(key)
    );
    const hasChangedInNewMetadata = newFieldMetadataKeys.some(
      key => projectFieldsMetadata[key]?.isLocked === true
    );
    return hasChangedInNewMetadata || hasChangedInOldMetadata;
  })();

  const checkIfFieldIsLocked = useMemo(
    () => (fieldId: string) => {
      if (!projectSchemaLookup || !projectFieldsMetadata[fieldId] || !projectId)
        return false;
      const fieldSchema = projectSchemaLookup[fieldId];
      return (
        fieldSchema.isLockable &&
        projectFieldsMetadata[fieldId].isLocked === true
      );
    },
    [projectSchemaLookup, projectFieldsMetadata, projectId]
  );

  const updateProjectFieldMetadata = useCallback(
    (fieldId: string, projectFieldMetadata: FieldMetadata) => {
      if (!projectId) return;

      dispatch(
        setFieldMetadata({
          projectId,
          schemaFieldId: fieldId,
          metadata: {
            ...projectFieldMetadata
          }
        })
      );
    },
    [dispatch, projectId]
  );

  const updateProjectMetadata = useCallback(
    (projectMetadata: { [key: string]: FieldMetadata }) => {
      if (!projectId) return;
      dispatch(
        setFieldsMetadata({
          projectId,
          metadata: projectMetadata
        })
      );
    },
    [dispatch, projectId]
  );

  return {
    projectFieldsMetadata,
    projectFieldsMetadataPristine,
    hasChanged,
    updateProjectMetadata,
    updateProjectFieldMetadata,
    checkIfFieldIsLocked
  };
};

export const usePreConId = () => {
  const dispatch = useDispatch();
  const [preConId, setPreConId] = useState<PreConId | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const token = useSelector(accountSelectors.getHcssToken);
  const businessUnitId = useSelectedBusinessUnitId();

  const projectSchema = useSelector(schemaSelectors.getProjectSchema);
  const isPreConIdDisabled =
    projectSchema?.fields?.["preconId"]?.hiddenInForm &&
    projectSchema?.fields?.["preconId"]?.hiddenInTable;

  const getAndReservePreConId = useCallback(async () => {
    if (isPreConIdDisabled || !token) return;
    try {
      const api = new ProjectsApi(token, businessUnitId);
      setIsLoading(true);
      const result = await api.getLastPreConIdAndReserveNext();
      if (Object.keys(result.data).length === 0) setPreConId(null);
      setPreConId(result.data);
      setIsLoading(false);
      return result.data;
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to get and reserve PreCon Id.");
      dispatch(loadPreConIdFailure());
    }
  }, [token, isPreConIdDisabled, businessUnitId]);

  const discardReservedPreConId = useCallback(
    async (preConId: string) => {
      if (isPreConIdDisabled || !token || !preConId) return;
      try {
        setIsLoading(true);
        const api = new ProjectsApi(token, businessUnitId);
        await api.discardReservedPreConId(preConId);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to discard the reserved PreCon Id.");
        dispatch(loadPreConIdFailure());
      }
    },
    [token, isPreConIdDisabled, businessUnitId]
  );

  const preConIdField = useSelector(schemaSelectors.getPreConIdField);

  return {
    isLoading,
    preConId,
    getAndReservePreConId,
    discardReservedPreConId,
    preConIdField
  };
};

export const useGetFilteredProjects = () => {
  const permissions = useSelector(accountSelectors.getPermissions);
  const filters = useSelector(selectors.getCurrentProjectViewFiltersList);
  const projectFields = useSelector(selectors.getProjectFields);
  const estimates = useSelector(getEstimatesLookup);

  const { allProjects, ...result } = useGetProjectsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      allProjects: result.data?.entities ?? {}
    })
  });

  const enrichedProjects = useMemo(() => {
    return Object.values(allProjects).map(p => {
      const projectCopy = fastDeepClone(p);
      enrichProjectWithEstimates(projectCopy, estimates || {}, projectFields);
      const calculatedFields = projectFields.filter(
        p => p.type === SchemaFieldType.Calculated
      );
      calculatedFieldService.setCalculateFields(
        calculatedFields,
        projectCopy,
        permissions?.estimateInsights ?? false
      );
      return projectCopy;
    });
  }, [allProjects, estimates, projectFields, permissions?.estimateInsights]);

  const projects = useMemo(() => {
    const projects = Object.values(enrichedProjects).filter(project => {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        const value = project.fields[filter.columnName];
        if (!filterField(filter.fieldType, filter, value)) {
          return false;
        }
      }
      return true;
    });

    return Object.fromEntries(projects.map(p => [p.id, p]));
  }, [enrichedProjects, filters]);

  return { projects, ...result };
};
