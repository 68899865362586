import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import FullPageLoading from "modules/layout/components/FullPageLoading";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as contactsActions,
  selectors as contactsSelectors
} from "modules/contacts";
import { notify } from "hcss-components";
import { useContactBUContextError } from "../hooks/useBUContextError";
import { strings } from "localization";
import { Fab } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ProtectedRoute } from "../../account/components/protected-route";

const VendorList = React.lazy(() =>
  import(
    /* webpackChunkName: "contacts-vendor-list" */ "../components/vendor-list"
  )
);

const VendorDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "contacts-vendor-details" */ "../components/vendor-details"
  )
);

const VendorEditDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "contacts-vendor-edit-details" */ "../components/vendor-edit-details"
  )
);

const ImportContacts = React.lazy(() =>
  import(/* webpackChunkName: "import-contacts" */ "../components/import/index")
);

const ContactsSetup = React.lazy(() =>
  import(/* webpackChunkName: "contact-setup" */ "../components/contact-setup")
);

const paths = {
  base: "/contacts",
  detail: "/contacts/:vendorId",
  edit: "/contacts/:vendorId/edit",
  setup: "/contacts/setup",
  import: "/contacts/import"
};

export const Views = () => {
  const dispatch = useDispatch();
  const showSearchModal = useSelector(contactsSelectors.getShowSearchModal);
  const existsInBUError = useContactBUContextError();

  if (existsInBUError) {
    notify("warning", strings.contactManagement.notInBU);
    return <Redirect to={paths.base} />;
  }

  return (
    <>
      <Fab
        variant="extended"
        data-testid="search-contacts-button"
        color="primary"
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
          fontWeight: "bold",
          fontSize: 14,
          paddingRight: "20px",
          zIndex: 900
        }}
        onClick={() => {
          dispatch(contactsActions.setShowSearchModal(!showSearchModal));
        }}
      >
        <SearchIcon sx={{ fontSize: 24, mr: "10px" }} />
        {strings.contactManagement.contactSearchButton.name}
      </Fab>
      <React.Suspense fallback={<FullPageLoading loading />}>
        <Switch>
          <ProtectedRoute
            isContactAddEdit
            exact
            path={paths.import}
            render={() => <ImportContacts />}
          />
          <ProtectedRoute
            isContactAddEdit
            exact
            path={paths.setup}
            render={() => <ContactsSetup />}
          />
          <ProtectedRoute
            isContactAddEdit
            exact
            path={paths.edit}
            render={() => <VendorEditDetails />}
          />
          <Route path="/contacts/:vendorId" render={() => <VendorDetails />} />
          <Route exact path="/contacts" component={VendorList} />
          <Route
            path="/contacts"
            render={() => <Redirect to={"/notfound"} />}
          />
        </Switch>
      </React.Suspense>
    </>
  );
};
