import React, { useRef, useEffect, useCallback } from "react";
import { usePreferences, actions as accountActions } from "modules/account";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions as settingsActions } from "modules/settings";
import { strings, languageOptions } from "localization";
import { UnifiedSelectPanel } from "./UnifiedSelectPanel";

export const LanguagePanel = () => {
  const dispatch = useDispatch();
  const userPreferences = usePreferences();

  const open = useSelector(selectors.getLanguagePanelActive);

  const onClose = () => dispatch(settingsActions.toggleLanguagePanel());

  const initialValue = useRef(userPreferences.language);
  useEffect(() => {
    if (initialValue.current !== userPreferences.language) {
      window.location.reload();
    }
  }, [initialValue, userPreferences]);

  const onSave = useCallback(
    (value: string) => {
      if (value === userPreferences.language) return;
      dispatch(
        accountActions.updatePreferences.request({
          ...userPreferences,
          language: value
        })
      );
    },
    [dispatch, userPreferences]
  );

  return (
    <UnifiedSelectPanel
      options={languageOptions}
      header={strings.layout.profile.language}
      open={open}
      onClose={onClose}
      onSave={onSave}
    />
  );
};
