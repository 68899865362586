import { strings } from "./strings";
import moment from "moment";

export * from "./dates";
export * from "./strings";

function getLanguageName(locale: string) {
  try {
    const languageDisplayName = new Intl.DisplayNames([locale], {
      type: "language"
    });
    let languageName = languageDisplayName.of(locale) as string;
    //capital case languageName
    languageName = capitalizeWords(languageName);
    return languageName;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}

export const regions = {
  "en-us": {
    id: "en-us",
    language: "en",
    dates: "en-us",
    name: strings.region.us
  },
  "en-au": {
    id: "en-au",
    language: "en",
    dates: "en-au",
    name: strings.region.au
  },
  "en-gb": {
    id: "en-gb",
    language: "en",
    dates: "en-gb",
    name: strings.region.gb
  },
  "en-ca": {
    id: "en-ca",
    language: "en",
    dates: "en-ca",
    name: strings.region.ca
  },
  "fr-ca": {
    id: "fr-ca",
    language: "fr",
    dates: "fr-ca",
    name: strings.region.fr
  }
};

export const regionOptions = Object.values(regions).map(region => ({
  value: region.id,
  label: region.name
}));

const capitalizeWords = (str: string) => {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const languages = {
  en: {
    id: "en",
    name: getLanguageName("en")
  },
  fr: {
    id: "fr-ca",
    name: getLanguageName("fr-ca")
  }
};

export const languageOptions = Object.values(languages)
  .filter(language => !!language.name)
  .map(language => ({
    value: language.id,
    label: language.name as string
  }));

export function switchRegion(region: keyof typeof regions) {
  //uncomment me to get obviously testable moment values
  // moment.locale("x-pseudo");
  //temporary translation solution
  const selectedRegion = regions[region] || regions["en-us"];

  moment.locale(selectedRegion.dates);
}

export function switchLanguage(language: keyof typeof languages) {
  strings.setLanguage(language);
}
