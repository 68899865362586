import React, { Fragment, useMemo } from "react";
import styled from "styled-components";
import { useLocation, useRouteMatch } from "react-router-dom";
import { strings } from "localization";
import { QuickSelect } from "core/components/sidenavigation/quick-select";
import { useCurrentEstimateContext } from "modules/estimates/context/CurrentEstimateContext";
import { ConcreteColors } from "hcss-components";
import { Dropdown } from "react-bootstrap";
import {
  SectionHeading,
  Item,
  MenuSection,
  PlaceholderDynamicMenu
} from "core/components/hcss-navigation";
import { useRecentEstimatesContext } from "modules/estimates/components/recent-estimates/use-recent-estimates";
import { useSelector } from "react-redux";
import { selectors } from "modules/estimates";
import { getPathForEstimate, paths } from "modules/estimates/views/paths";
import { HeaderSectionWithBackButton } from "../../common/header-section-with-back-button";
import { FeatureFlag } from "core";
import { useFeatureFlags } from "modules/configurationSettings/use-feature-flags";

export const EstimateDetailsContext = () => {
  const path = useLocation().pathname;
  const { currentEstimate } = useCurrentEstimateContext();
  const { recentEstimateIds } = useRecentEstimatesContext();
  const workingCopy = useSelector(selectors.getWorkingCopy);
  const { isFeatureEnabled: newQuoteManagementEnabled } = useFeatureFlags(
    FeatureFlag.NewQuoteManagement
  );
  const materialReportEnabled = useFeatureFlags("MaterialReport")
    .isFeatureEnabled;

  const estimateDetailsMatch = useRouteMatch<{ estimateId: string }>({
    path: "/estimates/:estimateId"
  })!;

  const options = useMemo(() => {
    const rawRecentEstimates = recentEstimateIds
      .map(estimateId => workingCopy[estimateId])
      .filter(estimate => estimate)
      .slice(1, 6);
    return rawRecentEstimates.map(e => {
      return {
        key: e.id ?? "",
        mainText: e.values.code ?? "(no code)",
        subText: e.values.name ?? "(no name)"
      };
    });
  }, [recentEstimateIds, workingCopy]);

  const estimateId = estimateDetailsMatch.params.estimateId;
  const estimateDetailsPath = `/estimates/${estimateId}`;
  const keyIndicatorsPath = `/estimates/${estimateId}/indicators`;
  const quotesSummaryPath = `/estimates/${estimateId}/quotes/summary`;
  const reportsPath = `/estimates/${estimateId}/reports`;
  const materialsPath = `/estimates/${estimateId}/materials`;
  const estimateCode = currentEstimate?.code ?? "";
  const estimateName = currentEstimate?.name ?? "";

  const dropDown = {
    options: [...options],
    singlePath: getPathForEstimate,
    allPath: paths.base,
    key: estimateId,
    optionHeader: strings.layout.menu.estimating.recentEstimates
  };

  if (!currentEstimate) {
    return <PlaceholderDynamicMenu />;
  }

  return (
    <div className="estimate-navigation-context">
      <HeaderSectionWithBackButton backPath={paths.base}>
        <div data-testid="navitem-estimateChooser">
          <QuickSelect dropDown={dropDown}>
            <ContextTitle>{estimateCode}</ContextTitle>
            <ContextSubtitle>{estimateName}</ContextSubtitle>
          </QuickSelect>
        </div>
      </HeaderSectionWithBackButton>
      <MenuSection>
        {menuStyle => (
          <ItemContainer style={menuStyle}>
            <SectionHeading>
              {strings.estimates.estimateAnalysis}
            </SectionHeading>
            <Item
              data-testid="navitem-estimateAnalysis-estimateDetails"
              key="estimate-details"
              text={strings.estimates.estimateDetails}
              isSelected={path === estimateDetailsPath}
              to={estimateDetailsPath}
            />
            <Item
              data-testid="navitem-estimateAnalysis-keyIndicators"
              key="key-indicators"
              text={strings.estimates.keyIndicators}
              isSelected={path === keyIndicatorsPath}
              to={keyIndicatorsPath}
            />
            <Item
              data-testid="navitem-estimateAnalysis-reporting"
              key="reporting"
              text={strings.estimates.reporting}
              isSelected={path === reportsPath}
              to={reportsPath}
            />
            {materialReportEnabled && (
              <Item
                data-testid="navitem-estimateAnalysis-materials"
                key="materials"
                text={strings.estimates.materials}
                isSelected={path === materialsPath}
                to={materialsPath}
              />
            )}
            {newQuoteManagementEnabled && currentEstimate.isActiveEstimate && (
              <Fragment>
                <SectionHeading>
                  {strings.estimates.quoteManagement}
                </SectionHeading>
                <Item
                  data-testid="navitem-quoteManagement-quoteSummary"
                  key="quote-summary"
                  text={strings.estimates.quoteSummary}
                  isSelected={path === quotesSummaryPath}
                  to={quotesSummaryPath}
                />
              </Fragment>
            )}
          </ItemContainer>
        )}
      </MenuSection>
    </div>
  );
};

const ItemContainer = styled.div`
  font-size: 14px;
  margin-right: 14px;
  color: ${props => props.theme.colors.neutral[0]};
`;

const ContextTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1e1e1e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContextSubtitle = styled.span`
  font-size: 12px;
  display: inline-block;
  position: relative;
  width: 220px;
  color: ${props => props.theme.colors.neutral[1]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ContextDropdownProps {
  isSelected: boolean;
  isHovering: boolean;
}
export const ContextDropdown = styled(Dropdown)<ContextDropdownProps>`
  margin-right: -8px;

  &&&& > button {
    border: 0;
    border-radius: 8px;
    box-shadow: none;
    background-color: transparent;
    color: ${p =>
      p.isSelected ? ConcreteColors.blue200 : ConcreteColors.gray600};

    & > span.caret {
      display: none;
      visibility: hidden;
    }
  }

  &&&& > ul.dropdown-menu {
    left: -155px;
    min-width: 195px;
    padding-top: 8px;
    padding-bottom: 8px;

    > li {
      padding-top: 5px;
    }
  }
`;
