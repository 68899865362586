import {
  ChangeSet,
  DataTypeProvider,
  EditingState
} from "@devexpress/dx-react-grid";
import {
  TableEditColumn,
  TableEditRow,
  TableHeaderRow
} from "@devexpress/dx-react-grid-bootstrap3";
import { notify } from "hcss-components";
import {
  BooleanFormatProvider,
  CommandComponent,
  DataColumnType,
  Grid,
  IntegratedSorting,
  SortingState,
  TableContainer,
  TypedDataColumn,
  useTableContext
} from "hcss-tables";
import produce from "immer";
import { cloneDeep } from "lodash-es";
import * as React from "react";
import { NoDataPlaceholder } from "../../../../core/components/containers/no-data-placeholder";

import { Flex } from "hcss-core";
import {
  selectors as accountSelectors,
  StateSlice as AccountStateSlice
} from "modules/account";
import {
  CustomEditCellComponent,
  CustomEditCellHeaderComponent
} from "modules/tables/cells/custom-cells-components";
import { connect } from "react-redux";
import * as configuratingSettings from "../../../configurationSettings";
import { selectors as configSelectors } from "../../../configurationSettings/state";
import { TableProps } from "../SchemaFormArrayField";
import { CheckListChart } from "./CheckListChart";
import LegacyVirtualTable from "core/components/bundle-fixes/LegacyVirtualTable";

export interface TableState {
  editingRowIds: any[];
  addedRows: any[];
  rowChanges: any;
}

export interface ChecklistTableProps extends TableProps {
  maxDescriptionLength?: number;
}

export class CheckList extends React.PureComponent<
  ChecklistTableProps,
  TableState
> {
  state = {
    editingRowIds: [] as any[],
    addedRows: [] as any[],
    rowChanges: {}
  };

  getColumns() {
    const { field } = this.props;
    const adminFailure =
      !this.props.permissions?.admin && this.props.field?.adminOnly;
    const checkCol = {
      id: "checked",
      name: "checked",
      title: "Checked",
      type: DataColumnType.Boolean,
      config: field.config,
      readOnly: field.readOnly || adminFailure,
      required: field.required
    };
    const desCol = {
      id: "description",
      name: "description",
      title: "Description",
      type: DataColumnType.LongText,
      config: field.config,
      readOnly: field.readOnly || adminFailure,
      required: field.required
    };
    // TODO: add customized TypeDataColumn
    const columns: TypedDataColumn[] = [
      checkCol as TypedDataColumn,
      desCol as TypedDataColumn
    ];
    return columns;
  }

  commitChanges = ({ added, changed, deleted }: ChangeSet) => {
    if (added && this.props.onChange) {
      const newObject = added[0];

      if (newObject.checked === undefined) {
        newObject.checked = false;
      }

      const { rows = [] } = this.props;
      const newRows = produce(rows, draft => {
        if (!draft) {
          draft = [];
        }
        draft.push(newObject);
      });
      this.props.onChange(newRows);
    }
    if (changed && this.props.onChange) {
      const changedIndex = parseInt(Object.keys(changed)[0], 10);
      const mergedObject = {
        ...this.props.rows[changedIndex],
        ...changed[changedIndex]
      };

      const newRows = produce(this.props.rows, draft => {
        draft[changedIndex] = mergedObject;
      });

      this.props.onChange(newRows);
    }
    if (deleted && this.props.onDelete) {
      this.props.onDelete(deleted[0]);
    }
  };

  changeAddedRows = (addedRows: any[]) => {
    this.setState({ addedRows });
  };

  changeEditingRowIds = (editingRowIds: any[]) => {
    this.setState({ editingRowIds });
  };

  changeRowChanges = (rowChanges: any) => {
    this.setState({ rowChanges });
  };

  validateEditedRow = (tableRow: any) => {
    const tempTableRow = cloneDeep(tableRow);
    const editedRowId = tableRow.rowId;
    const rowChanges: any = this.state.rowChanges;
    if (rowChanges[editedRowId]) {
      // when edited an existing row
      tempTableRow.row.checked =
        rowChanges[editedRowId].checked !== undefined
          ? rowChanges[editedRowId].checked
          : tempTableRow.row.checked;
      tempTableRow.row.description =
        rowChanges[editedRowId].description !== undefined
          ? rowChanges[editedRowId].description
          : tempTableRow.row.description;
    }

    if (
      tempTableRow.row.description === undefined ||
      tempTableRow.row.description === ""
    ) {
      notify("danger", `Error Saving Row`, "Description cannot be blank");
      return false;
    }

    if (
      this.props.maxDescriptionLength &&
      tempTableRow.row.description.length > this.props.maxDescriptionLength
    ) {
      notify(
        "danger",
        `Error Saving Row`,
        `Description exceeded ${this.props.maxDescriptionLength} character length limit`
      );
      return false;
    }

    return true;
  };

  render() {
    const { rows = [] } = this.props;
    const adminFailure =
      !this.props.permissions?.admin && this.props.field?.adminOnly;
    const isAddEditDisplay = this.props.permissions?.write;
    const columns = this.getColumns();
    const { editingRowIds, rowChanges, addedRows } = this.state;
    const isDisabled =
      !isAddEditDisplay ||
      adminFailure ||
      this.props.isLocked ||
      this.props.archived;
    return (
      <div data-testid="details-checklist-table">
        <TableContainer
          columns={columns}
          sections={[]}
          templateName="checklistResults"
        >
          <Flex
            bg="neutral.6"
            borderRadius={3}
            justifyContent="center"
            height="250px"
          >
            {rows.length <= 0 && this.state.addedRows.length <= 0 && (
              <this.EmptyCell />
            )}
            {(rows.length > 0 || this.state.addedRows.length > 0) && (
              <Grid rows={rows}>
                <BooleanFormatProvider />
                <EditingState
                  onCommitChanges={this.commitChanges}
                  editingRowIds={editingRowIds}
                  onEditingRowIdsChange={this.changeEditingRowIds}
                  rowChanges={rowChanges}
                  onRowChangesChange={this.changeRowChanges}
                  addedRows={addedRows}
                  onAddedRowsChange={this.changeAddedRows}
                  columnExtensions={columns.map(column => ({
                    columnName: column.name,
                    editingEnabled: !column.readOnly
                  }))}
                />
                <SortingState />
                <IntegratedSorting />
                <LegacyVirtualTable
                  columnExtensions={[
                    { columnName: "checked", width: 120, align: "center" }
                  ]}
                  noDataCellComponent={this.EmptyCell}
                />
                <TableHeaderRow showSortingControls />
                <TableEditRow />
                {(rows.length > 0 || this.state.addedRows.length > 0) && (
                  <TableEditColumn
                    width={90}
                    showAddCommand
                    showEditCommand
                    showDeleteCommand
                    commandComponent={props =>
                      TableCommandComponent({
                        tableEditColumnProps: props,
                        validationFunction: this.validateEditedRow,
                        isDisabled
                      })
                    }
                    cellComponent={CustomEditCellComponent}
                    headerCellComponent={CustomEditCellHeaderComponent}
                  />
                )}
              </Grid>
            )}
          </Flex>
        </TableContainer>
      </div>
    );
  }

  EmptyCell = () => {
    const adminFailure =
      !this.props.permissions?.admin && this.props.field?.adminOnly;
    const isAddEditDisplay =
      this.props.permissions?.write && !this.props.archived;
    return this.state.addedRows.length === 0 ? (
      <NoDataPlaceholder
        buttonText={
          isAddEditDisplay
            ? "Add Checklist Items"
            : "No Checklist Items to display"
        }
        onClick={() => this.changeAddedRows([{}])}
        isAddEditDisplay={
          !this.props.isLocked && isAddEditDisplay && !adminFailure
        }
      />
    ) : null;
  };
}

interface TableCommandComponentProps {
  tableEditColumnProps: TableEditColumn.CommandProps;
  validationFunction: (tableRow: any) => boolean;
  isDisabled: boolean | undefined;
}
const TableCommandComponent = ({
  tableEditColumnProps,
  validationFunction,
  isDisabled
}: TableCommandComponentProps) => {
  return (
    <CommandComponent
      {...tableEditColumnProps}
      validationFunction={validationFunction}
      disabled={isDisabled}
    />
  );
};

const CheckListFormatter = ({
  row,
  value,
  column
}: DataTypeProvider.ValueFormatterProps) => {
  if (!row || !value || value.length <= 0) {
    return <div />;
  }
  const data = checklistReducer(value);
  return (
    <div style={{ height: "25px" }}>
      <CheckListChart data={[{ ...data, project: column.title }]} />
    </div>
  );
};

export const CheckListProvider = () => {
  const { columns } = useTableContext();
  return React.useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={CheckListFormatter}
        editorComponent={CheckListFormatter}
        for={columns
          .filter(column => column.type === DataColumnType.CheckList)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

export const checklistReducer = (
  value: any[]
): { checked: number; notChecked: number } => {
  const defaultValue = { checked: 0, notChecked: 0 };
  if (!value) {
    return defaultValue;
  }
  return value.reduce(
    (acc: any, curr: any) => ({
      checked: acc.checked + (curr.checked ? 1 : 0),
      notChecked: acc.notChecked + (curr.checked ? 0 : 1)
    }),
    defaultValue
  );
};

const mapStateToProps = (
  state: configuratingSettings.StateSlice & AccountStateSlice,
  props: TableProps
) => ({
  permissions: accountSelectors.getPermissions(state),
  configuratingSettings: configSelectors.getConfigurationSettings(state),
  ...props
});

export default connect(mapStateToProps, {})(CheckList);
