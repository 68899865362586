import React, { useState } from "react";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { Button, Divider, IconButton } from "@mui/material";
import { ThemeProvider } from "@hcss/theme";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { strings } from "localization";

export interface UnifiedSelectPanelProps {
  options: { value: string; label: string }[];
  header: string;
  open: boolean;
  onClose: () => void;
  onSave: (value: string) => void;
}

export const UnifiedSelectPanel = ({
  options,
  header,
  open,
  onClose,
  onSave
}: UnifiedSelectPanelProps) => {
  const [selected, setSelected] = useState<string>("");

  return (
    <ThemeProvider baseFontSize={10}>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box
          sx={{
            width: "480px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper"
          }}
        >
          <StyledHeader>
            {header}
            <StyledIconButton onClick={onClose}>
              <CloseIcon
                sx={{ color: ({ palette }) => palette.secondary.dark }}
              />
            </StyledIconButton>
          </StyledHeader>
          <Divider />
          <div style={{ flexGrow: 1 }}>
            <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
              <Table>
                <TableBody>
                  {options.map(({ value, label }) => (
                    <StyledTableRow
                      key={value}
                      selected={value === selected}
                      onClick={() => setSelected(value)}
                    >
                      <StyledTableCell>{label}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Divider />
          <StyledFooter>
            <StyledWarning>
              <WarningIcon color="warning" sx={{ marginRight: "12px" }} />
              <span>{strings.layout.profile.unsavedChangesWarning}</span>
            </StyledWarning>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={!selected}
              onClick={() => {
                onSave(selected);
                onClose();
              }}
            >
              Switch
            </Button>
          </StyledFooter>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};

const StyledHeader = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledFooter = styled.div`
  position: sticky;
  bottom: 0;
  padding-left: 28px;
  padding-right: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 91px;
  width: 100%;
`;

const StyledWarning = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
`;

const StyledTableRow = styled(TableRow)`
  font-weight: 500;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  cursor: pointer;
`;

const StyledTableCell = styled(TableCell)`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 36px;
  padding-right: 36px;
`;

const StyledIconButton = styled(IconButton)`
  float: right;
`;
