import * as views from "./views";
import * as schemas from "./schemas";
import * as configurationSettings from "./configurationSettings";
import * as account from "./account";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectProjectErrors } from "./projects/projectsSlice";

const getErrorsState = createSelector(
  [
    account.selectors.getErrors,
    configurationSettings.selectors.getErrors,
    schemas.selectors.getErrors,
    views.selectors.getErrors
  ],
  (
    accountErrors: string[],
    configErrors: string[],
    schemasErrors: string[],
    viewsErrors: string[]
  ) => {
    return [
      ...accountErrors,
      ...configErrors,
      ...schemasErrors,
      ...viewsErrors
    ];
  }
);

const getSiteBreakingErrors = createSelector([getErrorsState], errors => {
  return errors.filter(e => !errorShouldBeIgnored(e));
});

export const useGetErrors = () => {
  const nonProjectErrors = useSelector(getErrorsState);
  const projectErrors = useSelector(selectProjectErrors);
  const errors = nonProjectErrors.concat(projectErrors);
  const siteBreakingErrors = errors.filter(e => !errorShouldBeIgnored(e));
  return { errors, siteBreakingErrors };
};

const errorShouldBeIgnored = (error: string) => {
  return errorsToIgnore.some(code => error.includes(code));
};

const errorsToIgnore = ["400", "403", "404"];
