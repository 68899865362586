import React, { ReactNode } from "react";
import styled, { css, createGlobalStyle } from "styled-components";
import { OverlayTrigger, Tooltip, ConcreteColors } from "hcss-components";
import { NamedColor } from "../../../../models/css-colors";
import { Badge } from "@mui/material";
import { SideNavigationOptions } from "modules/layout/components/navigation/side-navigation";
import { useGetUnlinkedEstimates } from "modules/estimates/hooks/use-unlinked-estimates";

interface SideNavigationMenuItemProps {
  hoverTitle: string;
  itemId: SideNavigationOptions;
  icon?: ReactNode;
  itemContainerStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  isSelected?: boolean;
  buttonColor?: {
    default: NamedColor;
    selected: NamedColor;
    hover: NamedColor;
  };
  buttonBackgroundColor?: {
    default: NamedColor;
    selected: NamedColor;
    hover: NamedColor;
  };
  onClick?: () => void;
  testId?: string;
}
export const GlobalItem = ({
  icon,
  itemId,
  hoverTitle,
  isSelected = false,
  itemContainerStyle,
  buttonStyle,
  buttonColor,
  buttonBackgroundColor,
  onClick,
  testId
}: SideNavigationMenuItemProps) => {
  return (
    <ItemWrapper
      className="side-nav-item-wrapper"
      data-testid={testId}
      style={itemContainerStyle}
    >
      <SideNavBadge itemId={itemId}>
        <OverlayTrigger
          placement="right"
          delayShow={400}
          overlay={
            <Tooltip id="tool-tip">
              <TooltipStyling />
              {hoverTitle}
            </Tooltip>
          }
        >
          <Button
            className="side-nav-item-button"
            isSelected={isSelected}
            buttonColor={buttonColor}
            buttonBackgroundColor={buttonBackgroundColor}
            style={buttonStyle}
            onClick={onClick}
          >
            {icon}
          </Button>
        </OverlayTrigger>
      </SideNavBadge>
    </ItemWrapper>
  );
};

interface SideNavBadgeProps {
  itemId: SideNavigationOptions;
  children: ReactNode;
}

const SideNavBadge = ({ itemId, children }: SideNavBadgeProps) => {
  switch (itemId) {
    case SideNavigationOptions.Projects:
      return <ProjectNavBadge>{children}</ProjectNavBadge>;
    default:
      return <>{children}</>;
  }
};

interface ProjectNavBadgeProps {
  children: ReactNode;
}

const ProjectNavBadge = ({ children }: ProjectNavBadgeProps) => {
  const { unlinkedEstimateCount } = useGetUnlinkedEstimates();

  return (
    <Badge
      badgeContent={unlinkedEstimateCount}
      color="error"
      variant="dot"
      overlap="circular"
    >
      {children}
    </Badge>
  );
};

const ItemWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px;
`;

const TooltipStyling = createGlobalStyle`
  .tooltip {
    background-color: ${ConcreteColors.gray600};
    margin: 0;
    border-radius: 4px;
    pointer-events:none;
    .tooltip-arrow {
      display: none;
    }
    
    .tooltip-inner {
      background-color: transparent;
      font-weight: 500;
      font-size: 13px;
    }
  }
`;

interface ButtonProps {
  isSelected: boolean;
  buttonColor?: {
    default: NamedColor;
    selected: NamedColor;
    hover: NamedColor;
  };
  buttonBackgroundColor?: {
    default: NamedColor;
    selected: NamedColor;
    hover: NamedColor;
  };
}
const Button = styled.button<ButtonProps>`
  ${p =>
    p.isSelected
      ? css`
          color: ${p.buttonColor?.selected ?? ConcreteColors.blue200};
          background-color: ${p.buttonBackgroundColor?.selected ??
          "rgba(0,0,0,0.08)"};
        `
      : css`
          color: ${p.buttonColor?.default ?? ConcreteColors.gray500};
          background-color: ${p.buttonBackgroundColor?.default ??
          "transparent"};
        `};

  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  border-width: 0px;
  outline: none;
  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    color: ${p => p.buttonColor?.hover ?? ConcreteColors.blue200};
    background-color: ${p =>
      p.buttonBackgroundColor?.hover ?? "rgba(0,0,0,0.08)"};
  }
`;
