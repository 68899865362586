import React from "react";
import constate from "constate";
import { useDispatch, useSelector } from "react-redux";
import { Tutorials } from "../../api";
import { tutorialSteps, tutorialStepsNoAddEdit } from "./tutorial-steps";
import { FeatureFlag } from "core";
import { useFeatureFlags } from "modules/configurationSettings/use-feature-flags";

import {
  actions as viewActions,
  selectors as viewSelectors
} from "modules/views";

import {
  usePermissions,
  actions as accountActions,
  selectors as accountSelectors
} from "modules/account";

const defaultTutorialState: Tutorials = {
  projectsList: true
};

export function useTutorial(tutorialId: keyof Tutorials) {
  const permissions = usePermissions();

  const preferences = useSelector(accountSelectors.getPreferences);
  const tutorials =
    preferences && preferences.tutorials
      ? preferences.tutorials
      : defaultTutorialState;

  const view = useSelector(viewSelectors.getCurrentProjectView);
  const dispatch = useDispatch();
  const [isOpen, setisOpen] = React.useState(false);

  const tutorialCompleted = tutorials[tutorialId];

  const {
    isFeatureEnabled: unifiedPlatformNavigationBarEnabled
  } = useFeatureFlags(FeatureFlag.UnifiedPlatformNavigationBar);

  const openTutorial = React.useCallback(() => {
    setisOpen(true);
    dispatch(
      viewActions.updateViewType(view.id, {
        list: true,
        calendar: false,
        charts: false,
        map: false
      })
    );
  }, [view, dispatch]);
  const closeTutorial = React.useCallback(() => {
    setisOpen(false);
    dispatch(accountActions.setTutorialCompleted(tutorialId));
  }, [dispatch, tutorialId]);

  return React.useMemo(
    () => ({
      showBanner:
        !tutorialCompleted && !isOpen && !unifiedPlatformNavigationBarEnabled,
      isOpen,
      openTutorial,
      closeTutorial,
      steps:
        permissions?.write ?? true
          ? tutorialSteps[tutorialId]
          : tutorialStepsNoAddEdit[tutorialId]
    }),
    [
      tutorialCompleted,
      isOpen,
      openTutorial,
      closeTutorial,
      permissions,
      tutorialId,
      unifiedPlatformNavigationBarEnabled
    ]
  );
}

const [provider, context] = constate(() => {
  const projectsList = useTutorial("projectsList");
  return { projectsList };
});

export const TutorialProvider = provider;
export const useTutorialContext = context;
