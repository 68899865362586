import produce from "immer";
import { keyBy, values, flatten } from "lodash-es";
import { createSelector } from "reselect";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";
import {
  Project,
  SchemaFieldType,
  EstimateEntity,
  SchemaField,
  JobDto,
  JobCostTypeCostDto
} from "api";
import { WithId } from "core";
import * as views from "modules/views";
import * as schemas from "modules/schemas";
import * as config from "modules/configurationSettings";
import * as account from "modules/account";
import * as calendars from "modules/calendars";
import * as estimates from "modules/estimates";
import {
  constantSections,
  populateProjectWithEstimateValues,
  PreConId,
  selectEstimatesFields
} from "./common";
import { calculatedFieldService } from "modules/schemas/services/calculated-field.service";

export const STATE_KEY = "projects";

// Models
export enum UndoEstimateLinkingStatus {
  InProgress = "InProgress",
  Fails = "Fails",
  Success = "Success",
  Ready = "Ready",
  OptimisticallyReady = "OptimisticallyReady"
}

export interface UndoEstimateLinkingState {
  status?: UndoEstimateLinkingStatus;
  projectId?: string;
  linkedEstimatesIds?: string[];
  error?: Error;
}
export interface State {
  hJJobs: JobDto[];
  hJJobCostTypeCosts: Record<string, JobCostTypeCostDto>;
  hJJobCostTypeCostsLoading: boolean;
  hJJobCostTypeCostsLoadingError?: Error;
}

export interface StateSlice {
  [STATE_KEY]: State;
}

// Actions
export const actions = {
  loadHJJobs: createAsyncAction(
    "HJJob/LOAD_REQUEST",
    "HJJob/LOAD_SUCCESS",
    "HJJob/LOAD_FAILURE"
  )<void, JobDto[], Error>(),

  loadHJJobCostTypeCosts: createAsyncAction(
    "HJJobCostTypeCost/LOAD_REQUEST",
    "HJJobCostTypeCost/LOAD_SUCCESS",
    "HJJobCostTypeCost/LOAD_FAILURE"
  )<{ jobIds?: string[] }, JobCostTypeCostDto[], Error>()
};

export type ProjectsActions = ActionType<typeof actions>;

const initialState: State = {
  hJJobs: [],
  hJJobCostTypeCosts: {},
  hJJobCostTypeCostsLoading: false
};

// Reducer
export const reducer = (state = initialState, action: ProjectsActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.loadHJJobs.success): {
        const jobs = action.payload;
        draft.hJJobs = jobs;
        break;
      }
      case getType(actions.loadHJJobs.failure): {
        draft.hJJobs = [];
        break;
      }
      case getType(actions.loadHJJobCostTypeCosts.request): {
        draft.hJJobCostTypeCostsLoading = true;
        break;
      }
      case getType(actions.loadHJJobCostTypeCosts.success): {
        const jobs = action.payload;

        for (const job of jobs) {
          draft.hJJobCostTypeCosts[job.jobId] = job;
        }

        draft.hJJobCostTypeCostsLoading = false;
        break;
      }
      case getType(actions.loadHJJobCostTypeCosts.failure): {
        draft.hJJobCostTypeCostsLoadingError = action.payload;
        draft.hJJobCostTypeCostsLoading = false;
        break;
      }
    }
  });
};

export type SelectorState = StateSlice &
  views.SelectorState &
  schemas.StateSlice &
  config.StateSlice &
  account.StateSlice &
  estimates.StateSlice;

// Selectors
const getEstimateHash = ({ estimates }: SelectorState) => estimates.workingCopy;
const getAllEstimatesLoaded = ({ estimates }: SelectorState) =>
  estimates.allLoaded;
const getHJJobs = ({ projects }: SelectorState) => projects.hJJobs;
const getHJJobCostTypeCosts = ({ projects }: SelectorState) =>
  projects.hJJobCostTypeCosts;
const getHJJobCostTypeCostsLoading = ({ projects }: SelectorState) =>
  projects.hJJobCostTypeCostsLoading;

const getProjectFieldsLookup = createSelector(
  [schemas.selectors.getCompleteProjectSchema],
  schema => {
    if (schema) {
      return schema.fields;
    }
    return {};
  }
);

const getProjectSectionsList = createSelector(
  [schemas.selectors.getProjectSchema],
  schema => {
    if (schema) {
      return schema.orderedSections;
    }
    return [];
  }
);

const getProjectSectionsLookup = createSelector(
  [schemas.selectors.getProjectSchema],
  schema => {
    if (schema) {
      return schema.sections;
    }
    return {};
  }
);

const getOrderedProjectSections = createSelector(
  [getProjectSectionsLookup, getProjectSectionsList],
  (sectionLookup, sectionList) => {
    return sectionList.map(sectionId => sectionLookup[sectionId]);
  }
);

const getProjectFields = createSelector(
  [getProjectFieldsLookup],
  fieldsLookup => {
    return values(fieldsLookup);
  }
);

const getFilterableProjectFields = createSelector([getProjectFields], fields =>
  fields.filter(field => !field.hiddenInTable)
);

const getFilterableProjectFieldsLookup = createSelector(
  [getFilterableProjectFields],
  fields => keyBy(fields, "id")
);

const getProjectFieldsBySection = createSelector(
  [getOrderedProjectSections, getProjectFieldsLookup],
  (sections, fieldsLookup) => {
    return sections.map(section => {
      return {
        ...section,
        fields: section.fields.map(fieldId => fieldsLookup[fieldId])
      };
    });
  }
);

const getFilterableProjectFieldsBySection = createSelector(
  [getOrderedProjectSections, getFilterableProjectFieldsLookup],
  (sections, fieldsLookup) => {
    return sections.map(section => {
      return {
        ...section,
        fields: section.fields
          .filter(fieldId => fieldId in fieldsLookup)
          .map(fieldId => fieldsLookup[fieldId])
      };
    });
  }
);

const getFilterableProjectFieldsOrdered = createSelector(
  [getFilterableProjectFieldsBySection],

  sections => {
    return flatten(sections.map(section => section.fields));
  }
);

export const getEstimateFields = createSelector(
  [getProjectFields],
  selectEstimatesFields
);

const getEstimateTotalRelatedFieldIds = createSelector(
  [getProjectFields],
  fields => {
    return fields
      .filter(field => {
        const isEstimateField =
          field.lookup &&
          (field.id.includes("values.totals") ||
            field.id.includes("values.customTotals"));

        const calculatedFieldConfig = calculatedFieldService.getConfig(field);
        const isCalculatedFieldWithEstimates =
          field.type === SchemaFieldType.Calculated &&
          calculatedFieldService.hasEstimatesVariables(calculatedFieldConfig);

        return isEstimateField || isCalculatedFieldWithEstimates;
      })
      .map(field => field.id);
  }
);
const getFilterableFields = createSelector([getProjectFields], fields => {
  return fields.filter(field => {
    if (field.hiddenInTable) {
      return false;
    }

    switch (field.type) {
      case SchemaFieldType.ShortText:
        return true;
      case SchemaFieldType.Boolean:
        return true;
      case SchemaFieldType.Number:
        return true;
      case SchemaFieldType.Currency:
        return true;
      case SchemaFieldType.Date:
        return true;
      case SchemaFieldType.DateTime:
        return true;
      case SchemaFieldType.List:
        return true;
      case SchemaFieldType.PreConId:
        return true;
      default:
        return false;
    }
  });
});

const getSelectableFields = createSelector([getFilterableFields], fields => {
  return fields.filter(
    field =>
      field.type !== SchemaFieldType.Number &&
      field.type !== SchemaFieldType.Currency
  );
});

export const getEstimatesLookup = createSelector(
  [getAllEstimatesLoaded, getEstimateHash],
  (loaded, hash) => {
    if (loaded) return hash;
    else return false;
  }
);

export interface LinkedEstimate {
  selectedEstimate?: boolean;
  id: string;
}

export const enrichProjectWithEstimates = (
  project: Pick<Project, "fields">,
  estimateLookup: Record<string, WithId<EstimateEntity>>,
  projectFields: SchemaField[]
) => {
  //get selectedEstimates for project as list of estimates
  const selectedEstimates: WithId<EstimateEntity>[] =
    project.fields.estimates
      ?.filter((estimate: LinkedEstimate) => {
        if (estimate.selectedEstimate && estimate.id in estimateLookup) {
          const selectedEstimate = estimateLookup[estimate.id];
          if (selectedEstimate?.values?.code) {
            return true;
          }
        }

        return false;
      })
      .map((estimate: LinkedEstimate) => estimateLookup[estimate.id]) ?? [];
  project.fields.estimates = project.fields.estimates?.map(
    (est: { id: string; selected?: boolean }) => ({
      ...est,
      code: estimateLookup[est.id]?.values?.code
    })
  );
  const estimateFields = selectEstimatesFields(projectFields);

  estimateFields.forEach(estimateField => {
    populateProjectWithEstimateValues(
      project,
      estimateField,
      selectedEstimates
    );
  });
};

export const enrichProjectWithTableLookupFields = (
  project: Pick<Project, "fields">,
  projectFields: SchemaField[]
) => {
  const tableLookupFields = projectFields.filter(
    f => f.config.tableLookupField
  );

  for (const field of tableLookupFields) {
    const columnId = field.config.columnId;

    const value: Record<string, number>[] =
      project.fields[field.config.tableFieldId];

    if (!value) {
      continue;
    }

    project.fields[field.id] = value.reduce(
      (acc, curr) => acc + +(curr[columnId as string] || 0),
      0
    );
  }
};

const getCurrentProjectViewFiltersLookup = createSelector(
  [views.selectors.getCurrentProjectView],
  view => {
    return view?.filters ?? [];
  }
);

const getCurrentProjectViewFiltersList = createSelector(
  [
    getCurrentProjectViewFiltersLookup,
    getProjectFieldsLookup,
    schemas.selectors.isPreConIdEnabled
  ],
  (filters, fields, isPreConIdEnabled) => {
    return values(filters)
      .filter(filter => {
        if (filter.columnName === "preconId" && !isPreConIdEnabled)
          return false;
        return filter.columnName in fields && filter.value !== null;
      })
      .map(filter => ({
        ...filter,
        fieldType: fields[filter.columnName].type
      }));
  }
);

const getIsProjectsFilteredByEstimateTotals = createSelector(
  [getCurrentProjectViewFiltersList, getEstimateTotalRelatedFieldIds],
  (filters, fieldIds) => {
    for (const filter of filters) {
      const isFilterValueEmpty =
        filter.value?.from === undefined && filter.value?.to === undefined;

      if (fieldIds.includes(filter.columnName) && !isFilterValueEmpty)
        return true;
    }
    return false;
  }
);

const getCurrentProjectViewFields = createSelector(
  [views.selectors.getCurrentProjectView, getProjectFields],
  (view, fields) => {
    if (view && fields) {
      return fields.filter(field => view.displayedColumns.includes(field.id));
    }
    return [];
  }
);

const getSelectableFieldList = createSelector([getSelectableFields], fields => {
  return fields
    .map(column => ({ display: column.name, value: column.id }))
    .sort((a, b) =>
      a.display.localeCompare(b.display, undefined, { sensitivity: "base" })
    );
});

const getShareableFieldsFromProjectSection = createSelector(
  [getProjectFieldsBySection],
  sections => {
    const firstSection = sections.filter(
      s => !constantSections.includes(s.id)
    )[0];

    if (!firstSection) {
      return [];
    }

    return firstSection.fields
      .reduce((result: SchemaField[], curr) => {
        if (
          curr.type !== SchemaFieldType.Estimates &&
          curr.type !== SchemaFieldType.BidResults &&
          curr.type !== SchemaFieldType.Links &&
          curr.type !== SchemaFieldType.Checklist
        ) {
          result.push(curr);
        }
        return result;
      }, [])
      .filter(field => !field.hiddenInTable);
  }
);

export const selectors = {
  getProjectSchema: schemas.selectors.getProjectSchema,
  getProjectFieldsLookup,
  getProjectFields,
  getCurrentProjectViewFields,
  getCurrentProjectView: views.selectors.getCurrentProjectView,
  getCurrentProjectViewClean: views.selectors.getCurrentProjectViewClean,
  getFilterableFields,
  getSelectableFields,
  getCurrentProjectViewFiltersList,
  getProjectFieldsBySection,
  getFilterableProjectFieldsBySection,
  getCalendarSubscribeIsActive: calendars.selectors.getCalendarPanelActive,
  getFilterableProjectFieldsOrdered,
  getSelectableFieldList,
  getShareableFieldsFromProjectSection,
  getIsProjectsFilteredByEstimateTotals,
  getHJJobs,
  getHJJobCostTypeCosts,
  getHJJobCostTypeCostsLoading
};
